import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RadialUser } from '../../models/user';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  isClickedOnDashboardIcon = false;
  // pass selected client in header to all components .. dont remove its using
  selectedClientInHeader = new BehaviorSubject<any>([]);
  private readonly users$ = new BehaviorSubject<RadialUser>(null);
  // for ping userinfo
  addUser(user) {
    const existingData = this.users$.getValue();
    if (existingData) {
      this.users$.next({ ...user, ...existingData });
    } else {
      this.users$.next(user);
    }
  }
  getLoggedInUserInfo() {
    return this.users$.asObservable();
  }
}
